import dynamic from 'next/dynamic'
const Error404 = dynamic(() => import('../templates/Error404'))

const Error404Page = (props) => {
    
    return (
        
            <Error404 isAppleMobile={props.isAppleMobile} />
        
    );
    
}

export default Error404Page;

